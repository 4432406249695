import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { defaultNavItems } from './navItems';
import { Link, useNavigate } from 'react-router-dom';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import '../styles/dashboard.css';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { fetchUserData } from '../../database/fetch-user-data';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../resources/gcp-config';
import { handleLogout } from '../../auth/auth';
const auth = getAuth(app);

// add NavItem prop to component prop
const Sidebar = ({
  collapsed,
  navItems = defaultNavItems,
  shown,
  setCollapsed,
  isCropLoading,
}) => {
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  const location = useLocation();

  let navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  /* The `useEffect` hook in this code is used to fetch user data from the database and update the
`userData` state variable. */
  useEffect(() => {
    fetchUserData()
      .then((data) => {
        setUserData(data);
        localStorage.setItem('name', data.name.split(' ')[0]);
      })
      .catch((error) => {
        console.error('Error fetching user data: ', error);
      });
  }, [isCropLoading]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem('email', user.email);
      }
    });
  }, []);
  return (
    <div
      className={classNames({
        'bg-gray-800 text-zinc-50 relative md:translate-x-0 z-20': true,
        'transition-all duration-300 ease-in-out': true,
        'w-[300px]': !collapsed,
        'w-16': collapsed,
        '-translate-x-full': !shown,
        // 'z-20': !collapsed,
        // 'z-0': collapsed,
      })}
    >
      <div
        className={classNames({
          'flex flex-col justify-between h-screen sticky inset-0 w-full': true,
        })}
      >
        {/* logo and collapse button */}
        <div
          className={classNames({
            'flex items-center border-b border-b-gray-800 transition-none': true,
            'p-4 justify-between': !collapsed,
            'py-4 justify-center': collapsed,
          })}
        >
          {!collapsed && (
            <span className="whitespace-nowrap">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/yoda-technologies-kb.appspot.com/o/serving-again-logo.png?alt=media&token=3dd3a271-c210-407f-a952-7d8e26f9ffc0"
                alt="Company Logo"
                className="px-3"
              />
            </span>
          )}
          <button
            className="grid place-content-center hover:bg-gray-800 w-10 h-10 rounded-full opacity-0 md:opacity-100"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon className="w-5 h-5" />
          </button>
        </div>
        <nav className="flex-grow">
          <ul
            className={classNames({
              'my-2 flex flex-col gap-2 items-stretch': true,
            })}
          >
            {navItems.map((item, index) => {
              let isActive = location.pathname == item.href; // Check if item is the current page
              return (
                <li
                  key={index}
                  className={classNames({
                    'text-gray-100 hover:bg-gray-900 flex': true, //colors
                    'transition-colors duration-300': true, //animation
                    'rounded-md p-2 mx-3 gap-4 ': !collapsed,
                    'rounded-full p-2 mx-3 w-10 h-10': collapsed,
                    'bg-gray-700': isActive, // Add color for active item
                  })}
                >
                  <Link to={item.href} className="flex gap-2">
                    {item.icon} <span>{!collapsed && item.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div
          className={`${
            !collapsed ? 'mx-2 pl-2' : 'mx-2 pl-1 py-1'
          }  mb-0  border border-solid rounded-md`}
        >
          <Dropdown
            arrowIcon={false}
            inline
            className="ml-2"
            label={
              <Avatar
                placeholderInitials={userData?.name
                  ?.split(' ')
                  .map((name) => name.charAt(0))
                  .join('')}
                rounded
                img={userData?.photoURL || undefined}
                className="avtar-icon"
              >
                {!collapsed && (
                  <div className="pl-1 py-3 text-sm text-white flex flex-col text-start dark:text-white">
                    <div>{userData?.name || '...'}</div>
                    <div className="font-medium truncate">
                      {userData?.primaryEmail || '...'}
                    </div>
                  </div>
                )}
              </Avatar>
            }
          >
            <div className="px-4 py-3 text-sm text-white dark:text-white">
              <div>{userData?.name || '...'}</div>
              <div className="font-medium truncate">
                {userData?.primaryEmail || '...'}
              </div>
            </div>
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li>
                <a
                  href="/dashboard"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/profile"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Edit Profile
                </a>
              </li>
            </ul>
            <div className="py-2">
              <button
                className="px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left inline-flex items-center font-semibold"
                onClick={() => handleLogout(navigate)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                  />
                </svg>
                Sign out
              </button>
            </div>
          </Dropdown>
        </div>
        <div
          className={classNames({
            'grid place-content-stretch p-4 ': true,
          })}
        ></div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  navItems: PropTypes.array,
  setCollapsed: PropTypes.func,
  shown: PropTypes.bool,
};

export default Sidebar;
