import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import RFQStats from '../components/dashboard/rfq-stats';
import Calendar from '../components/dashboard/calendar';
import Notifications from '../components/dashboard/notifications';
import { fetchAllRfqData } from '../database/fetch-rfq-data';
import { fetchAllPosData } from '../database/fetch-pos-data';
import { fetchAllDeliveriesData } from '../database/fetch-deliveries-data';
import '../components/styles/dashboard.css';
import { app } from '../resources/gcp-config';
import '../components/styles/dashboard.css';

import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
  Timestamp,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';

const db = getFirestore(app);

function Dashboard(props) {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [statsData, setStatsData] = useState([]); // Declare state

  const loadData = () => {
    // Pass callback functions
    fetchAllRfqData((rfqData) => {
      fetchAllPosData((poData) => {
        fetchAllDeliveriesData((deliveriesData) => {
          let currentMonthRFQCount = 0;
          let pastMonthRFQCount = 0;
          let percentageRFQGrowth = 0;

          let currentMonthRfqSentCount = 0;
          let pastMonthRfqSentCount = 0;
          let percentageRfqSentGrowth = 0;

          let currentMonthPOCount = 0;
          let pastMonthPOCount = 0;
          let percentagePOGrowth = 0;

          let currentMonthPOInvoiced = 0;
          let pastMonthPOInvoiced = 0;
          let percentagePOInvoicedGrowth = 0;

          const countReleasedThisMonth = (arr) =>
            arr.filter((item) => {
              // get the current date
              let now = new Date();

              // get the current month and year
              let currentMonth = now.getMonth();

              let currentYear = now.getFullYear();

              // get past month and year
              let pastMonth = now.getMonth() - 1;
              let pastYear = now.getFullYear();
              if (pastMonth < 0) {
                // convert past month to Dec of last year if it is Jan now
                pastMonth = 11;
                pastYear -= 1;
              }

              // parse the month and year from the releaseDate in the item
              let itemDate = new Date(item.releaseDate);

              // console.log(itemDate);
              let itemYear = itemDate.getFullYear();
              let itemMonth = itemDate.getMonth();

              // increment count if item is released in current or past month
              if (itemMonth === currentMonth && itemYear === currentYear) {
                currentMonthRFQCount++;

                if (item.packetStatus === 'Sent') {
                  currentMonthRfqSentCount++;
                }
              } else if (itemMonth === pastMonth && itemYear === pastYear) {
                pastMonthRFQCount++;

                if (item.packetStatus === 'Sent') {
                  pastMonthRfqSentCount++;
                }
              }

              if (pastMonthRFQCount === 0) {
                pastMonthRFQCount = 1;
              }

              if (pastMonthRfqSentCount === 0) {
                pastMonthRfqSentCount = 1;
              }

              return {
                currentMonthCount: currentMonthRFQCount,
                pastMonthCount: pastMonthRFQCount,
                currentMonthRfqSentCount: currentMonthRfqSentCount,
                pastMonthRfqSentCount: pastMonthRfqSentCount,
              };
            });

          countReleasedThisMonth(rfqData);

          // console.log(pastMonthRFQCount, currentMonthRFQCount);

          if (pastMonthRFQCount !== 0) {
            percentageRFQGrowth =
              ((currentMonthRFQCount - pastMonthRFQCount) / pastMonthRFQCount) *
              100;
          } else {
            // If the denominator is 0, then set growth to be 100%
            percentageRFQGrowth = 100;
          }

          let formattedRfqGrowthPercent;

          if (percentageRFQGrowth >= 0) {
            formattedRfqGrowthPercent =
              '+' + Math.abs(percentageRFQGrowth).toFixed(2) + '%';
          } else {
            formattedRfqGrowthPercent =
              '-' + Math.abs(percentageRFQGrowth).toFixed(2) + '%';
          }

          if (pastMonthRfqSentCount !== 0) {
            percentageRfqSentGrowth =
              ((currentMonthRfqSentCount - pastMonthRfqSentCount) /
                pastMonthRfqSentCount) *
              100;
          } else {
            // If the denominator is 0, then set growth to be 100%
            percentageRfqSentGrowth = 100;
          }

          let formattedRfqSentGrowthPercent;

          if (percentageRfqSentGrowth >= 0) {
            formattedRfqSentGrowthPercent =
              '+' + Math.abs(percentageRfqSentGrowth).toFixed(2) + '%';
          } else {
            formattedRfqSentGrowthPercent =
              '-' + Math.abs(percentageRfqSentGrowth).toFixed(2) + '%';
          }

          const countPOThisMonth = (arr) => {
            arr.filter((item) => {
              // get the current date
              let now = new Date();

              // get the current month and year
              let currentMonth = now.getMonth();
              let currentYear = now.getFullYear();

              // get past month and year
              let pastMonth = now.getMonth() - 1;
              let pastYear = now.getFullYear();
              if (pastMonth < 0) {
                // convert past month to Dec of last year if it is Jan now
                pastMonth = 11;
                pastYear -= 1;
              }

              // parse the month and year from the releaseDate in the item
              let itemDate = new Date(item.awardedDate);
              // console.log(itemDate);
              let itemYear = itemDate.getFullYear();
              let itemMonth = itemDate.getMonth();

              // increment count if item is PO in current or past month
              if (itemMonth === currentMonth && itemYear === currentYear) {
                currentMonthPOCount++;
              } else if (itemMonth === pastMonth && itemYear === pastYear) {
                pastMonthPOCount++;
              }

              if (pastMonthPOCount === 0) {
                pastMonthPOCount = 1;
              }
              return {
                currentMonthPOCount: currentMonthPOCount,
                pastMonthPOCount: pastMonthPOCount,
              };
            });
          };

          countPOThisMonth(poData);

          if (pastMonthPOCount !== 0) {
            percentagePOGrowth =
              ((currentMonthPOCount - pastMonthPOCount) / pastMonthPOCount) *
              100;
          } else {
            // If the denominator is 0, then set growth to be 100%
            percentagePOGrowth = 100;
          }

          let formattedPOGrowthPercent;
          if (percentagePOGrowth >= 0) {
            formattedPOGrowthPercent =
              '+' + Math.abs(percentagePOGrowth).toFixed(2) + '%';
          } else {
            formattedPOGrowthPercent =
              '-' + Math.abs(percentagePOGrowth).toFixed(2) + '%';
          }

          const countInvoicedPOThisMonth = (arr) => {
            arr.filter((item) => {
              if (item.invoiceSent !== 'Sent') {
                return;
              }

              // get the current date
              let now = new Date();

              // get the current month and year
              let currentMonth = now.getMonth();
              let currentYear = now.getFullYear();

              // get past month and year
              let pastMonth = now.getMonth() - 1;
              let pastYear = now.getFullYear();
              if (pastMonth < 0) {
                // convert past month to Dec of last year if it is Jan now
                pastMonth = 11;
                pastYear -= 1;
              }

              // parse the month and year from the invoiceDate in the item
              let itemDate = new Date(item.invoiceSentDate.seconds * 1000);

              let itemYear = itemDate.getFullYear();
              let itemMonth = itemDate.getMonth();

              // increment count if item is invoiced in current or past month
              if (itemMonth === currentMonth && itemYear === currentYear) {
                currentMonthPOInvoiced++;
              } else if (itemMonth === pastMonth && itemYear === pastYear) {
                pastMonthPOInvoiced++;
              }

              if (pastMonthPOInvoiced === 0) {
                pastMonthPOInvoiced = 1;
              }

              return {
                currentMonthInvoiced: currentMonthPOInvoiced,
                pastMonthInvoiced: pastMonthPOInvoiced,
              };
            });
          };

          countInvoicedPOThisMonth(deliveriesData);

          if (pastMonthPOInvoiced !== 0) {
            percentagePOInvoicedGrowth =
              ((currentMonthPOInvoiced - pastMonthPOInvoiced) /
                pastMonthPOInvoiced) *
              100;
          } else {
            // If there were no invoiced POs in the last month, then set growth to be 100%
            percentagePOInvoicedGrowth = 100;
          }

          let formattedPOInvoicedGrowthPercent;
          if (percentagePOInvoicedGrowth >= 0) {
            formattedPOInvoicedGrowthPercent =
              '+' + Math.abs(percentagePOInvoicedGrowth).toFixed(2) + '%';
          } else {
            formattedPOInvoicedGrowthPercent =
              '-' + Math.abs(percentagePOInvoicedGrowth).toFixed(2) + '%';
          }

          let dataset = [
            {
              StatContent: 'RFQs Found',
              StatCount: currentMonthRFQCount, // params depends on actual data
              StatChange: formattedRfqGrowthPercent,
            },
            {
              StatContent: 'RFQs Closed',
              StatCount: currentMonthRfqSentCount,
              StatChange: formattedRfqSentGrowthPercent,
            },
            {
              StatContent: 'SPOs Awarded',
              StatCount: currentMonthPOCount,
              StatChange: formattedPOGrowthPercent,
            },
            {
              StatContent: 'SPOs Invoiced',
              StatCount: currentMonthPOInvoiced,
              StatChange: formattedPOInvoicedGrowthPercent,
            },
          ];

          // console.log(deliveriesData);
          // console.log(poData);
          // console.log(rfqData);

          // console.log(dataset);

          setStatsData(dataset);
        });
      });
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const fetchMails = async (pageSize, startAfterDoc) => {
    console.log('loading');
    const fifteenDaysAgo = Timestamp.fromMillis(
      Date.now() - 1 * 24 * 60 * 60 * 1000
    );

    let q = query(
      collection(db, 'email-records'),
      where('timestamp', '>=', fifteenDaysAgo),
      orderBy('timestamp', 'desc'), // Order by timestamp
      limit(pageSize) // Limit the number of documents per page
    );

    // If startAfterDoc is provided, use it to paginate
    if (startAfterDoc) {
      q = query(q, startAfter(startAfterDoc));
    }

    const querySnapshot = await getDocs(q);
    let mails = [];
    querySnapshot.forEach((doc) => {
      const mail = doc.data();
      mails.push({ ...mail, id: doc.id });
    });
    console.log('fetching done', mails);

    // Return an object with mails array and the last document snapshot for pagination
    return {
      mails,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Get the last document snapshot
    };
  };

  const fetchAndLogMails = async () => {
    const batchSize = 8; // Adjust batch size as needed
    let lastDoc = null; // Initialize last document snapshot
    let allResponses = [];

    const processBatch = async (batch) => {
      const promises = batch.map((mail) => {
        return fetch('http://localhost:3001/test/inbound-emails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(mail),
        })
          .then((res) => res.json())
          .catch((err) => console.log(err));
      });
      const batchResponses = await Promise.all(promises);
      return batchResponses;
      // const filteredResponses = batchResponses.filter(
      //   (response) => response.message !== 'Not a Delivery mail'
      // );
      // allResponses.push(...filteredResponses);
      // return filteredResponses;
    };

    // Function to fetch the next page of mails
    const nextPage = async () => {
      const { mails, lastDoc: nextLastDoc } = await fetchMails(
        batchSize,
        lastDoc
      );
      if (mails.length > 0) {
        const responses = await processBatch(mails);
        console.log('Batch responses:', responses);
        lastDoc = nextLastDoc; // Update last document snapshot for pagination
        // Recursively call nextPage until there are no more mails
        return;
        await nextPage();
      } else {
        console.log('All mails fetched.');
        console.log('All filtered responses:', allResponses);
      }
    };

    // Start fetching the first page of mails
    await nextPage();
  };

  const testExcel = async () => {
    fetch('http://localhost:3001/test/excel-extraction', {
      method: 'POST',
    })
      .then(async (res) => console.log(await res.json()))
      .catch((err) => console.log(err));
  };
  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <div>
          <Sidebar
            className="sidebar" // Add the classes here
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
            onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
          />
        </div>
        <div className="container 2xl:max-w-full" style={{ overflow: 'auto' }}>
          <NavbarElement
            onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
          />
          <div className="flex justify-around items-center my-6">
            {/* <button className="p-5 bg-slate-500" onClick={testExcel}>
            Test Mail
          </button> */}
            {statsData.length >= 4 ? (
              <>
                <RFQStats data={statsData[0]} className="w-1/4 px-6" />
                <RFQStats data={statsData[1]} className="w-1/4 px-6" />
                <RFQStats data={statsData[2]} className="w-1/4 px-6" />
                <RFQStats data={statsData[3]} className="w-1/4 px-6" />
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
          <div className="flex justify-around items-center my-6">
            <Notifications />
          </div>
          <div className="my-6 mx-auto px-6">
            <Calendar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
