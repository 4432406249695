import CreateEmailTemplateModal from '../components/settings/create-email-temp-modal';
import EmailTemplateTable from '../components/settings/email-temp-table';
import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';

function SettingsPage(props) {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openEmailTemplateModal, setOpenEmailTemplateModal] = useState(false);
  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container 2xl:max-w-full" style={{ overflow: 'auto' }}>
          <div className="">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            {props.children}
            <div>
              <h1 className="font-semibold text-2xl pt-5  px-2">
                Email Templates
              </h1>
              <p className="px-2 w-full text-right pb-1">
                Double click a row to edit the template
              </p>
              <EmailTemplateTable />
              <button
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 m-5 focus:outline-none text-sm inline-flex items-center"
                onClick={() => setOpenEmailTemplateModal(true)}
              >
                <svg
                  className="flex-shrink-0 w-5 h-5 mr-3 -ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                Create New Email Template
              </button>
            </div>
          </div>
          <CreateEmailTemplateModal
            openModal={openEmailTemplateModal}
            setOpenModal={setOpenEmailTemplateModal}
          />
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
