import React, { useEffect, useState } from 'react';
import { fetchAllEmailTemplates } from '../../database/fetch-email-template';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import CreateEmailTemplateModal from './create-invoice-temp-modal';
import { deleteEmailTemplate } from '../../database/set-email-template';
import { fetchAllInvoiceTemplates } from '../../database/fetch-invoice-template';
import CreateInvoiceTemplateModal from './create-invoice-temp-modal';
import { deleteInvoiceTemplate } from '../../database/set-invoice-template';

const gridStyle = { height: '65vh' };

const InvoiceTempTable = () => {
  const [invoiceTemplates, setInvoiceTemplates] = useState([]);
  const [openInvoiceTemplateModal, setOpenInvoiceTemplateModal] =
    useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  useEffect(() => {
    fetchAllInvoiceTemplates((templates) => {
      setInvoiceTemplates(templates);
    });
  }, []);

  const handleMailTypeRender = ({ value, data }) => {
    if (value === 'rfq') {
      return 'RFQ';
    } else if (value === 'rfq_rq') {
      return 'RFQ Request Quotes';
    } else if (value === 'pos_oq') {
      return 'POS Original Quotes';
    } else if (value === 'pos_mq') {
      return 'POS Modified Quotes';
    } else if (value === 'pos_co') {
      return 'POS Confirmation';
    } else if (value === 'del_sh') {
      return 'Deliveries Shipment';
    } else if (value === 'del_in') {
      return 'Deliveries Invoices';
    } else {
      return value;
    }
  };

  const onDeleteRenderer = ({ data }) => {
    // // console.log('idData: ', data);

    return (
      <button
        type="button"
        onClick={() => deleteInvoiceTemplate(data.docId)}
        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
      >
        Delete
      </button>
    );
  };

  const tableColumns = () => [
    {
      name: 'index',
      header: '#',
      minWidth: 50,
      render: ({ data, rowIndex }) => (
        <div style={{ textAlign: 'center' }}>{rowIndex + 1}</div>
      ),
    },
    { name: 'title', header: 'Title', minWidth: 250 },
    {
      name: 'delete',
      header: 'Delete',
      minWidth: 150,
      render: ({ value, data }) => onDeleteRenderer({ data }),
    },
  ];

  const tableFilterValue = [
    { name: 'title', operator: 'contains', type: 'string', value: '' },
    { name: 'mailType', operator: 'contains', type: 'string', value: '' },
  ];

  const handleRowDoubleClick = (e, props) => {
    // console.log('Data', props.data);
    setSelectedTemplate(props.data);
    setOpenInvoiceTemplateModal(true);
  };

  return (
    <>
      <ReactDataGrid
        style={gridStyle}
        idProperty="emailTempId"
        defaultFilterValue={tableFilterValue}
        columns={tableColumns()}
        dataSource={invoiceTemplates}
        pagination
        defaultLimit={10}
        rowHeight={60}
        onRowDoubleClick={(e, props) => handleRowDoubleClick(e, props)}
        showCellBorders={'horizontal'}
        columnMenu={{ lockable: false }}
      />

      <CreateInvoiceTemplateModal
        openModal={openInvoiceTemplateModal}
        setOpenModal={setOpenInvoiceTemplateModal}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        type={'edit'}
      />
    </>
  );
};

export default InvoiceTempTable;
