import React from 'react';
import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import { useState } from 'react';
import CoTable from '../components/contractingOfficer/co-table';
import FloatingButton from '../components/floating-button';
import AddNewCoComponent from '../components/contractingOfficer/add-new-co';

const ContractingOfficerPage = (props) => {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container 2xl:max-w-full" style={{ overflow: 'auto' }}>
          <div className="h-screen flex flex-col">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            <div className="flex-grow overflow-hidden">
              <CoTable />
            </div>
          </div>
          <FloatingButton
            onClick={() => setOpenModal(true)}
            text={'Add New CO'}
          />
          <AddNewCoComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractingOfficerPage;
