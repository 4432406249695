import ReportsTable from '../components/reports/reports-table';
import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';
import ReportsChart from '../components/reports/reports-charts';
import '../components/styles/dashboard.css';

function ReportsPage(props) {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [chartData, setChartData] = useState({});

  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container 2xl:max-w-full" style={{ overflow: 'auto' }}>
          <div className="h-screen flex flex-col">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            <div className="flex-grow overflow-scroll">
              <ReportsTable chartData={chartData} setChartData={setChartData} />
              <ReportsChart chartData={chartData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsPage;
